<template>
  <div class="modal team-modal">
    <div
      class="modal-overlay"
      @click="closeModal"></div>
    <div class="modal-content">
      <div
        class="modal-close"
        @click="closeModal">
        <span></span>
        <span></span>
      </div>
      <div class="video-container">
        <video
          v-if="type == 'file'"
          ref="video"
          controls
          controlsList="nodownload"
          oncontextmenu="return false;">
          <source
            :src="data.url"
            :type="`${data.type}/${data.subtype}`" />
        </video>
        <iframe
          v-else-if="type == 'youtube'"
          :src="`https://www.youtube.com/embed/${data}?rel=0&autoplay=1&cc_load_policy=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <iframe
          v-else-if="type == 'vimeo'"
          :src="`https://player.vimeo.com/video/${data}?&autoplay=1&title=0&byline=0&portrait=0`"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, inject } from 'vue'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

const props = defineProps(['type', 'data'])

const videoModal = inject('videoModal')
const { locoScroll } = useSmoothScroll()

const video = ref(null)

const closeModal = () => {
  videoModal.value.open = false
  videoModal.value.data = null
}

const closeHandler = (e) => {
  if (e.keyCode === 27) {
    closeModal()
  }
}

onMounted(() => {
  document.body.classList.add('no-overflow')
  locoScroll.value.stop()
  document.addEventListener('keyup', closeHandler, { passive: true })

  if (props.type == 'file') {
    video.value.play()
  }
})

onBeforeUnmount(() => {
  document.body.classList.remove('no-overflow')
  locoScroll.value.start()
  document.removeEventListener('keyup', closeHandler, { passive: true })
})
</script>

<style lang="scss">
.team-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal-close {
    cursor: pointer;
    position: absolute;
    top: -60px;
    right: 0;
    width: 60px;
    height: 60px;

    @include mobile {
      top: 12px;
      right: 12px;
      width: 30px;
      height: 30px;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 30px;
      height: 3px;
      background-color: $white;

      @include mobile {
        width: 15px;
        height: 2px;
      }

      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .modal-content {
    position: relative;
    width: 90%;
    max-width: 1115px;
    max-height: 90vh;
    z-index: 1;
    -webkit-overflow-scrolling: touch;

    .video-container {
      overflow: hidden;
      padding: 0 0 56.25%;
      position: relative;
      height: 0;
      border-radius: 20px;
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.35);

      iframe,
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      video {
        object-fit: cover;
      }
    }
  }
}
</style>
